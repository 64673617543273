// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Footer() {
  return (
    <footer className="bg-dark text-light mt-5">
      <Container>
        <Row className="py-3">
          <Col md={4}>
            <h5>xAI</h5>
            <p>The Home of Decentralized AI</p>
          </Col>
          <Col md={8}>
            <Row>
              <Col md={4} xs={6}>
                <Link to="/privacy-policy" className="text-light text-decoration-none">Privacy Policy</Link>
              </Col>
              <Col md={4} xs={6}>
                <Link to="/cookie-policy" className="text-light text-decoration-none">Cookie Policy</Link>
              </Col>
              <Col md={4} xs={6}>
                <Link to="/terms-and-conditions" className="text-light text-decoration-none">Terms</Link>
              </Col>
              <Col md={4} xs={6}>
                <Link to="/disclaimer" className="text-light text-decoration-none">Disclaimer</Link>
              </Col>
              <Col md={4} xs={6}>
                <Link to="/acceptable-use-policy" className="text-light text-decoration-none">Acceptable Use</Link>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="py-2">
          <Col>
            <p className="mb-0">&copy; {new Date().getFullYear()} xAI. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;