import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import './HomePage.css';
import CarouselComponent from './CarouselComponent';
import PackageCards from './PackageCards';
import { Lightbulb, Brain, Search, CircleDollarSign } from 'lucide-react';

function HomePage({ userState, setUserState }) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const packagesRef = useRef(null);

  const startSession = (packageAssistantId, voiceName) => {
    setUserState({
      ...userState,
      agentId: packageAssistantId,
      voiceName: voiceName
    });
    navigate(`/therapy-session`);
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedCard(null);
    setShowModal(false);
  };

  const scrollToPackages = () => {
    packagesRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <CarouselComponent scrollToPackages={scrollToPackages} />
      
      <Container className="mt-4 mb-4" >

        <Card className="mb-4 custom-bg-color">
          <Card.Body>

          <section className="mb-12">
          <h2 className="marketing-title">Collaborate. Create. Earn.</h2>
          <br></br>
        {/* <p className="mb-4">
          RAG uses a combination of advanced techniques to search through your data to optimize the context you send to the AI model:
        </p> */}
        <div className="grid md:grid-cols-2 gap-4 mb-4">
          <div className="bg-blue-100 p-4 rounded-lg flex items-center cursor-pointer">
            <Lightbulb className="w-8 h-8 text-blue-500 mr-2" />
            <span>Build AI Solutions in Collaboration with Others</span>
          </div>
          <div className="bg-purple-100 p-4 rounded-lg flex items-center cursor-pointer">
            <Brain className="w-8 h-8 text-purple-500 mr-2" />
            <span>Build Creative Content in Collboration with Others</span>
          </div>
          <div className="bg-red-100 p-4 rounded-lg flex items-center cursor-pointer">
            <Search className="w-8 h-8 text-red-500 mr-2" />
            <span>Discover Solutions and Request Custom  or Content with Bounties</span>
          </div>
          <div className="bg-yellow-100 p-4 rounded-lg flex items-center cursor-pointer">
            <CircleDollarSign className="w-8 h-8 text-yellow-500 mr-2" />
            <span>Track contributions and IP on the Blockchain, Earn Tokens</span>
          </div>
        </div>
        {/* <p>
          By combining these search methods, RAG finds the best possible knowledge from your stores which is added to the pre-existing knowledge and intelligence of the AI to provide the best possible answers to your questions.
        </p> */}
        </section>

            <div>
              {/* <h2 className="marketing-title">AI Therapist, Coach and Counselor Designed for College Students</h2>
              <p className="marketing-text">
                Our innovative platform provides you with tailored support, guidance, and resources to help you navigate the challenges of academic life and maintain your mental well-being.
              </p> */}

                <h2 className="feature-title">Discover and Use the latest AI innovations from our App Store!</h2>

            </div>
          </Card.Body>
        </Card>

        <PackageCards 
          startSession={startSession}
          handleCardClick={handleCardClick}
          packagesRef={packagesRef}
        />

      </Container>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedCard?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Problem:</strong> {selectedCard?.problem}</p>
          <p><strong>Solution:</strong> {selectedCard?.solution}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={() => startSession(selectedCard?.packageAssistantId, selectedCard?.voiceName)}>
            Start Session
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default HomePage;