import React from 'react';
import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from './HomePage';
import ConsumePage from './ConsumePage';
import BuildPage from './BuildPage';
import WalletPage from './WalletPage';
import Footer from './Footer';

function App() {
  return (
    <BrowserRouter>
      <div className="d-flex flex-column min-vh-100">
        <Navbar bg="dark" variant="dark" expand="lg" className="mb-3">
          <Container>
            <Navbar.Brand as={NavLink} to="/" className="brand-logo">
              <span className='swagin-logo'>x</span>AI
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link as={NavLink} to="/consume">Solutions</Nav.Link>
                <Nav.Link as={NavLink} to="/build">Build</Nav.Link>
                <Nav.Link as={NavLink} to="/wallet">Wallet</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Container className="flex-grow-1">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/consume" element={<ConsumePage />} />
            <Route path="/build" element={<BuildPage />} />
            <Route path="/wallet" element={<WalletPage />} />
          </Routes>
        </Container>

        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;