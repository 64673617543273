import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import cardData from './cardData.json';

function PackageCards({ startSession, handleCardClick, packagesRef }) {
  return (
    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3" ref={packagesRef}>
      {cardData.map((card, index) => (
        <div key={index} className="col">
          <Card className="shadow-sm">
            <Card.Img variant="top" src={card.image} />
            <Card.Body>
              <Card.Title className="card-title">{card.title}</Card.Title>
              <Card.Text className="card-text">{card.problem}</Card.Text>
              <div className="d-flex justify-content-between align-items-center">
                <Button variant="dark" onClick={() => startSession(card.packageAssistantId, card.voiceName)}>
                  Start Session
                </Button>
                <Button variant="outline-secondary" size="sm" onClick={() => handleCardClick(card)}>
                  More Info
                </Button>
              </div>
            </Card.Body>
          </Card>
        </div>
      ))}
    </div>
  );
}

export default PackageCards;