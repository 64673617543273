import React, { useState, useMemo } from 'react';
import { Container, Row, Col, Card, Form, InputGroup, Badge, Button } from 'react-bootstrap';
import { Search, Star, Download } from 'lucide-react';
import './ConsumePage.css';
import allAppsData from './allApps.json';

const categories = ["Productivity", "Creativity", "Education", "Entertainment", "Health & Fitness"];

export default function ConsumePage() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [includeThirdParty, setIncludeThirdParty] = useState(true);

  const filteredApps = useMemo(() => {
    return allAppsData.filter(app => 
      (includeThirdParty || !app.isThirdParty) &&
      (selectedCategory === '' || app.category === selectedCategory) &&
      (app.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
       app.description.toLowerCase().includes(searchTerm.toLowerCase()))
    ).sort((a, b) => {
      // Sort own apps first
      if (a.isThirdParty === b.isThirdParty) return 0;
      return a.isThirdParty ? 1 : -1;
    });
  }, [searchTerm, selectedCategory, includeThirdParty]);

  const renderAppCard = (app) => (
    <Col key={app.id} md={4} className="mb-4">
      <Card className="h-100 app-card">
        <Card.Img variant="top" src={app.image} />
        <Card.Body>
          <Card.Title>{app.name}</Card.Title>
          <Card.Text className="app-description">{app.description}</Card.Text>
          <div className="app-tags mb-2">
            <span className="category">{app.category}</span>
            {app.keywords && app.keywords.map(keyword => (
              <span key={keyword} className="ms-1">#{keyword}</span>
            ))}
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="app-rating">
              <Star size={16} fill="#ffc107" stroke="#ffc107" /> {app.rating}
            </div>
            <div className="app-downloads">
              <Download size={16} /> {app.downloads}
            </div>
          </div>
          {app.featured && <Badge bg="primary" className="featured-badge">Featured</Badge>}
          {app.new && <Badge bg="success" className="new-badge">New</Badge>}
        </Card.Body>
        <Card.Footer>
          <Button variant="outline-dark" className="w-100">Try Now</Button>
        </Card.Footer>
      </Card>
    </Col>
  );

  return (
    <Container className="mt-4 mb-4">
      <Row className="mb-3 align-items-center">
        <Col>
          <div className="d-flex align-items-center">
            <h3 className="me-3 mb-0">Browse Solutions</h3>
            <Form.Check 
              type="checkbox"
              id="includeThirdParty"
              label="Include 3rd Party"
              checked={includeThirdParty}
              onChange={(e) => setIncludeThirdParty(e.target.checked)}
              className="mb-0"
            />
          </div>
        </Col>
      </Row>
  
      <Form className="mb-4">
        <Row>
          <Col md={8}>
            <InputGroup>
              <InputGroup.Text><Search size={20} /></InputGroup.Text>
              <Form.Control 
                type="text" 
                placeholder="Search apps..." 
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>
          </Col>
          <Col md={4}>
            <Form.Select 
              value={selectedCategory} 
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">All Categories</option>
              {categories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </Form.Select>
          </Col>
        </Row>
      </Form>

      <Row>
        {filteredApps.map(app => renderAppCard(app))}
      </Row>
    </Container>
  );
}