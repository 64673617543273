import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';

function CarouselComponent({ scrollToPackages }) {
  return (
    <Carousel className="full-width-carousel" controls={false}>
      <Carousel.Item>
        <div className="carousel-image-container">
          <img
            className="d-block carousel-image"
            src="xai1.webp"
            alt="Start your journey to better mental health."
          />
        </div>
        <Carousel.Caption>
          <h3 className="carousel-title">xAI is Collaborative, Democratized and Decentralized AI</h3>
          <Button variant="light" size="sm" className="carousel-button" onClick={scrollToPackages}>
            Start to Build
          </Button>
        </Carousel.Caption>
      </Carousel.Item>
      {/* <Carousel.Item>
        <div className="carousel-image-container">
          <img
            className="d-block carousel-image"
            src="xai1.webp"
            alt="Start your journey to better mental health."
          />
        </div>
        <Carousel.Caption>
          <h3 className="carousel-title">Free - 24/7 - AI Therapist and Coach</h3>
          <Button variant="dark" size="sm" className="carousel-button" onClick={scrollToPackages}>
            Try Now
          </Button>
        </Carousel.Caption>
      </Carousel.Item> */}
      <Carousel.Item>
        <div className="carousel-image-container">
          <img
            className="d-block carousel-image"
            src="xai2.jpg"
            alt="Start your journey to better mental health."
          />
        </div>
        <Carousel.Caption>
          {/* <h3 className="carousel-title">AI for the People, By the People</h3> */}
          <Button variant="dark" size="sm" className="carousel-button" onClick={scrollToPackages}>
            Start Earning
          </Button>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselComponent;