import React, { useState, useEffect, useCallback } from 'react';
// import { useNavigate } from 'react-router-dom'; // Remove this line if not using navigation
import { ethers } from 'ethers';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';

function WalletPage() {
  const [error, setError] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [tokens, setTokens] = useState([]);
  const [rewardPoints, setRewardPoints] = useState([]);
  // const navigate = useNavigate(); // Remove this line if not using navigation

  const checkWalletConnection = useCallback(async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const accounts = await provider.listAccounts();
        if (accounts.length > 0) {
          setIsConnected(true);
          fetchUserData(accounts[0]);
        }
      } catch (err) {
        console.error('Error checking wallet connection:', err);
      }
    }
  }, []);

  useEffect(() => {
    checkWalletConnection();
  }, [checkWalletConnection]);

  const connectWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();

        const message = `Login to MyApp: ${new Date().toISOString()}`;
        const signature = await signer.signMessage(message);

        const response = await fetch('/api/login', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ address, message, signature }),
        });

        if (response.ok) {
          setIsConnected(true);
          fetchUserData(address);
        } else {
          const data = await response.json();
          setError(data.message || 'Login failed');
        }
      } catch (err) {
        console.error('Wallet connection error:', err);
        if (err.code === 4001) {
          setError('You rejected the connection request. Please try again.');
        } else if (err.message.includes('Already processing eth_requestAccounts')) {
          setError('Wallet connection is already in progress. Please check your wallet extension.');
        } else {
          setError('Failed to connect wallet. Please try again or use a different browser.');
        }
      }
    } else {
      setError('No Ethereum wallet detected. Please install MetaMask or another compatible wallet.');
    }
  };

  const fetchUserData = async (address) => {
    try {
      const response = await fetch(`/api/user/${address}`);
      if (response.ok) {
        const data = await response.json();
        setUserProfile(data.profile);
        setTokens(data.tokens);
        setRewardPoints(data.rewardPoints);
      } else {
        setError('Failed to fetch user data');
      }
    } catch (err) {
      setError('An error occurred while fetching user data');
      console.error('Error fetching user data:', err);
    }
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const updatedProfile = Object.fromEntries(formData.entries());

    try {
      const response = await fetch('/api/user/profile', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedProfile),
      });

      if (response.ok) {
        setUserProfile(updatedProfile);
        alert('Profile updated successfully');
      } else {
        setError('Failed to update profile');
      }
    } catch (err) {
      setError('An error occurred while updating profile');
      console.error('Error updating profile:', err);
    }
  };

  return (
    <Container className="mt-4 mb-4">
      <Card className="mb-4 custom-bg-color">
        <Card.Body>
          <Card.Title>Wallet</Card.Title>
          {error && <Alert variant="danger">{error}</Alert>}
          {!isConnected ? (
            <Button variant="primary" onClick={connectWallet}>
              Connect Wallet
            </Button>
          ) : (
            <>
              <h4>User Profile</h4>
              <Form onSubmit={handleProfileUpdate}>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" name="firstName" defaultValue={userProfile.firstName} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" name="lastName" defaultValue={userProfile.lastName} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" name="email" defaultValue={userProfile.email} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control type="tel" name="phone" defaultValue={userProfile.phone} />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Update Profile
                </Button>
              </Form>

              <h4 className="mt-4">Token Holdings</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Token ID</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {tokens.map((token) => (
                    <tr key={token.id}>
                      <td>{token.id}</td>
                      <td>{token.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <h4 className="mt-4">Reward Points</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Point Type</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {rewardPoints.map((reward) => (
                    <tr key={reward.id}>
                      <td>{reward.point_type}</td>
                      <td>{reward.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}

export default WalletPage;