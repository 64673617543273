import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

function BuildPage() {
  return (
    <Container className="mt-4 mb-4">
      <Card className="mb-4 custom-bg-color">
        <Card.Body>
          <Card.Title>Build AI Services</Card.Title>
          <Card.Text>
            Here you can create and contribute to AI services on our platform.
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default BuildPage;